<template>
  <div class="main-layout">
    <router-view />
    <o-button v-if="this.$route.name != 'home'" type="secondary" class="o-btn--secondary quit-button" :label="getEmsDataPropertyText('stop')"  @click.native="quit"></o-button>
  </div>
</template>

<script>
import CONST from '@/utils/Constants'
import ApiHelper from "@/helpers/ApiHelper";
import ErrorHelper from "@/helpers/ErrorHelper";
// @ is an alias to /src
import oButton from '@/objects/o-button/o-button.vue';

export default {
  name: "Main",
  components : {
    'o-button': oButton
  },
  created() {
    this.$store.dispatch('fetchEmsData');
  },
  methods: {
    setDefaultBackgroundLargeScreen () {
      let data = {
          "message": this.getEmsDataPropertyText('global_background_image'),
          "channel": CONST.PUSHER.CHANNEL,
          "event-name": CONST.EVENT.BACKGROUND.SELECTED,
        }

        ApiHelper.post(CONST.API.BACKGROUND.SELECT, data, {headersType: 'basic'})
        .catch(error => {
            ErrorHelper.catch(error, this);
        });
    },
    quit () {
      this.setDefaultBackgroundLargeScreen();
      this.$router.push({name: 'home'});
    }
  }
};

</script>

<style>

body {
  background-image: url('../assets/img/share-email-2-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.quit-button {
  position: fixed;
  top: 48px;
  right: 56px;
  z-index: 100;
}

.main-layout .quit-button p {
  background-color: #999999;
  width: fit-content;
}

</style>